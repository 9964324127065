<template>
  <div>
    <div class="flex items-center justify-between mb-2">
      <h2>{{$t('Product Catalog')}}</h2>
      <vs-alert class="w-1/2" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
        {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
      </vs-alert>
      <vs-dropdown v-else
        vs-trigger-click
        ref="card"
        class="dropdown dd-actions cursor-pointer mr-4 mb-4"
      >
        <vs-button
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
        <vs-dropdown-menu class="add-shipment-dropdown">
          <vs-dropdown-item class="step-selector-2" @click="openProductModal('New Product')">
            <span class="flex items-center text-blue-900 hover:text-primary">
              <feather-icon
                icon="PlusIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('New Product')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="uploadFiletype('uploadFulfillment')" class="new-img">
            <div class="flex" style="position: revert;">
            <span class="flex items-center">
              <feather-icon
                icon="UploadIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Upload Multiple Orders')}}</span>
            </span>
            </div>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <div class="lg:col-span-2 col-span-3 clearfix">
      <div class="lg:float-left flex gap-4 justify-end items-center lg:mt-6">
        <div v-if="merchantStatus === 'active'" :class="selectedFlag ? 'cursor-pointer' : 'cursor-not-allowed'">
          <div :class="selectedFlag ? 'available-action' : 'pointer-events-none unavailable-action'" class="px-4 py-2 flex items-center" @click="stockModal = true">
            <feather-icon icon="ShoppingCartIcon" svgClasses="w-5 h-5"/>
            <span class="text-sm ml-2">{{ $t('Create Stock Request') }}</span>
          </div>
        </div>
        <div v-if="merchantStatus === 'active'" :class="selectedFlag ? 'cursor-pointer' : 'cursor-not-allowed'">
          <div :class="selectedFlag ? 'available-action' : 'pointer-events-none unavailable-action'" class="px-4 py-2 flex items-center" @click="addStockRecall()">
            <feather-icon icon="ShoppingCartIcon" svgClasses="w-5 h-5"/>
            <span class="text-sm ml-2">{{ $t('Create Stock Recall') }}</span>
          </div>
        </div>
        <print-label-button class="flex self-center" :selected="selected"></print-label-button>
        <div class="cursor-pointer vs-con-loading__container float-right" id="download-btn-loading">
          <div @click="downloadCatalog()">
            <vs-button class="px-4 py-2"> {{ 'Download Catalog' }}</vs-button> 
          </div>
        </div>
      </div>
      <shipblu-table
      :sst="true"
        multiple
        v-model="selected"
        pagination
        :max-items="maximumItems"
        @search="handleSearch"
        @keyup.enter.native="handleSearch"
        search
        :data="products"
        :tableLoader="tableLoader"
      >
        <template slot="thead">
          <shipblu-th>{{$t('Product Image')}}</shipblu-th>
          <shipblu-th>{{$t('SBSIN')}}</shipblu-th>
          <shipblu-th>{{$t('SKU')}}</shipblu-th>
          <shipblu-th>{{$t('Name')}}</shipblu-th>
          <shipblu-th>{{$t('Description')}}</shipblu-th>
          <shipblu-th>{{$t('Size')}}</shipblu-th>
          <shipblu-th>{{$t('Color')}}</shipblu-th>
          <shipblu-th>{{$t('Dimensions')}}</shipblu-th>
          <shipblu-th>{{$t('Current Inventory')}}</shipblu-th>
          <shipblu-th>{{$t('Supplier')}}</shipblu-th>
          <shipblu-th>{{$t('Actions')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].image">
              <img :src=" data[indextr].image " class="w-10 h-10"/>
            </shipblu-td>

            <shipblu-td :data="data[indextr].shipBluInventoryID">
              {{ data[indextr].id }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].sku">
              {{ data[indextr].sku }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].name">
              {{ data[indextr].name }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].description">
              {{ data[indextr].description }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].size">
              {{ data[indextr].size }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].color">
              <div class="color-container h-6 w-3/4">
                <div class="w-full h-full" :style="`background-color:${data[indextr].color}`"></div>
                <p class="mt-2" v-if="!data[indextr].color.includes('#')">{{data[indextr].color}}</p>
              </div>
            </shipblu-td>

            <shipblu-td :data="data[indextr].dimensions" style="direction: ltr;">
              {{ data[indextr].d_length }} x {{ data[indextr].d_width }} x {{ data[indextr].d_height }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].currentInventoryLevel">
              {{ data[indextr].current_inventory_level }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].supplier">
              {{ data[indextr].supplier }}
            </shipblu-td>

            <shipblu-td>
              <vs-dropdown
                :disabled="merchantStatus === 'on_hold'"
                vs-trigger-click
                class="dd-actions cursor-pointer"
                @click="selected = []"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openProductModal('Edit Product', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    </div>
    <print-product-barcode :selected="selected"/>
    <add-stock-request :selected="selected" @selected="selected = $event" :stockModal="stockModal" @stockModal="stockModal = $event" type="New Stock Request" :stockRequest="stockRequest"></add-stock-request>
    <add-product :productModal="productModal" @productModal="productModal = $event" @loadProductCatalog="loadProductCatalog" :type="type" :product="product"></add-product>
    <upload-shipments-modal :uploadFileType="uploadFileType" :uploadShipmentsModal="uploadShipmentsModal" @uploadShipmentsModal="uploadShipmentsModal = $event" />
  </div>
</template>

<script>
import AddProduct from './components/AddProduct.vue'
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import AddStockRequest from './components/AddStockRequest.vue'
import PrintLabelButton from '../../headOfFleet/components/PrintLabelButton.vue'
import PrintProductBarcode from '../components/PrintProductBarcode.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import UploadShipmentsModal from '../components/UploadShipmentsModal.vue'
import i18nData from '../../../i18n/i18nData'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      productModal: false,
      type: '',
      products: [],
      product: {},
      merchantID: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      selectedFlag: false,
      stockRequest: {},
      stockModal: false,
      tableLoader: false,
      printProduct: {},
      merchantStatus: '',
      uploadFileType: '',
      uploadShipmentsModal: false
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadProductCatalog()
    },
    selected (val) {
      if (val.length > 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    addStockRecall () {
      const stockRecallObj = {
        fulfillment_center: 1,
        products: []
      }
      this.selected.forEach(item => {
        stockRecallObj.products.push({
          product: item.id
        })
      })
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/stock-recalls/', 'post', stockRecallObj, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock recall'] + i18nData[this.$i18n.locale][' has been created successfully!'],
            position: 'top-center'
          })
          this.selected = []
          this.loadProductCatalog()     
        }
      )
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadProductCatalog()
    },
    loadProductCatalog () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?merchant=${this.merchantID}&offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/products/${query}`, 'get', null, true,
        (response) => {
          this.products = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    openProductModal (type, data) {
      this.type = type
      if (type === 'New Product') {
        this.product = Object.assign({}, {})
        this.product.color = '#000'
      } else {
        this.product = data
        this.product.supplier = this.product.supplier ? this.product.supplier : ''
        this.product.reorder_count = this.product.reorder_count ? this.product.reorder_count : 0
      }
      this.productModal = true
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductCatalogSearch)
    },
    loadProductCatalogSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadProductCatalog()
    },
    printLabel (product) {
      this.printProduct = product
      window.print()
    },
    uploadFiletype (type) {
      this.uploadFileType =  type
      this.uploadShipmentsModal = true
    },
    downloadCatalog () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#download-btn-loading',
        scale: 0.45
      })
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/products/export/', 'post', {}, true,
        (response) => {
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.$vs.loading.close('#download-btn-loading > .con-vs-loading')
        }
      )
    }
  },
  components: {
    AddProduct,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddStockRequest,
    PrintLabelButton,
    PrintProductBarcode,
    ShipbluPagination,
    UploadShipmentsModal
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantID = user.id
      this.merchantStatus = user.status
    })
    this.loadProductCatalog()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
</style>